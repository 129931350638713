<template>
  <b-row>
    <b-col cols="12" class="mt-1">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" v-b-modal.modal-addcategory class="my-1">
          Add Category
        </b-button>
      </div>
    </b-col>
    <b-modal
      id="modal-addcategory"
      cancel-variant="outline-secondary"
      centered
      title="Add Category"
      hide-footer
      @hidden="reDo()"
    >
      <b-form @submit.prevent="AddCategory" ref="addCategory">
        <b-form-group>
          <label for="Name">Category Name</label>
          <b-form-input
            type="text"
            placeholder="Category Name"
            v-model="categoryName"
            required
          />
        </b-form-group>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" block>
            <b-spinner small v-if="loading" class="mx-2" />Add
          </button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-editcategory"
      cancel-variant="outline-secondary"
      centered
      @hidden="reDo()"
      title="Edit Category"
      hide-footer
    >
      <b-form @submit.prevent="EditCategory" ref="editCategory">
        <b-form-group>
          <label for="Name">Category Name</label>
          <b-form-input
            type="text"
            required
            minlength="1"
            placeholder="Category Name"
            v-model="categoryData.name"
          />
        </b-form-group>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" block>
            <b-spinner small v-if="loading" class="mx-2" />Edit
          </button>
        </div>
      </b-form>
    </b-modal>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1"> </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="categoryList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-b-modal.modal-editcategory
              @click="getitemCategoryData(data.item)"
            >
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeitemCategory(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="warning"
          show
          v-if="categoryList.length === 0"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">No categories yet, please add</h4>
        </b-alert>
      </div>

      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="danger"
          show
          v-if="errorCall"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">An Error has occured, please try again later</h4>
        </b-alert>
      </div>

      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
      <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BForm,
  BBadge,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BAlert,
    BBadge,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  data() {
    return {
      perPage: 5,
      categoryName: "",
      errorCall: false,
      categoryData: [],
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "name",
          label: "Name",
        },
        "Actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("itemcategoryModule", {
      categoryList: "itemcategories",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    reDo() {
      this.getItemCategoryListAction();
    },
    info(item, index, button) {
      this.infoModal.titlea = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    getitemCategoryData(data) {
      this.categoryData = data;
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    removeitemCategory(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeItemCategoryAction(id)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your category has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              this.$swal({
                title: "Delete failed!",
                text: error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your category is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    successAdd() {
      this.$swal({
        title: "Category Added!",
        text: "You have successfully added a Category type!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorAdd(error) {
      this.$swal({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    successEdit() {
      this.$swal({
        title: "Category type edited!",
        text: "You have successfully edited a Category type!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorEdit(error) {
      this.$swal({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    EditCategory() {
      let payload = {
        name: this.categoryData.name,
        id: this.categoryData.id,
      };
      this.updateItemCategoryAction(payload)
        .then(() => {
          this.successEdit();
          this.$bvModal.hide("modal-editcategory");
          this.getItemCategoryListAction();
        })
        .catch((error) => {
          this.errorEdit(error);
          this.$bvModal.hide("modal-editcategory");
          this.getItemCategoryListAction();
        });
    },

    AddCategory() {
      let payload = {
        name: this.categoryName,
      };
      this.addItemCategoryAction(payload)
        .then(() => {
          this.successAdd();
          this.$bvModal.hide("modal-addcategory");
          this.$refs.addCategory.reset();
          this.categoryName = "";
        })
        .catch((error) => {
          this.errorAdd(error);
          this.$bvModal.hide("modal-addcategory");
          this.getItemCategoryListAction();
        });
    },

    ...mapActions("itemcategoryModule", [
      "getItemCategoryListAction",
      "removeItemCategoryAction",
      "addItemCategoryAction",
      "updateItemCategoryAction",
    ]),
  },

  async mounted() {
    await this.getItemCategoryListAction()
      .then(() => {
        this.totalRows = this.categoryList.length;
      })
      .catch((response) => {
        this.errorCall = true;
      });
  },
};
</script>
